import React from "react";
import { Alert, Box } from "@mui/material";
import PropTypes from "prop-types";

const AlertInfo = ({ severity, text }) => {
  //serverity is: "success", "info", "warning", or "error"

  return (
    <Box sx={{ margin: "20px 0" }}>
      <Alert severity={severity}>{text}</Alert>
    </Box>
  );
};

AlertInfo.propTypes = {
  severity: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

AlertInfo.defaultProps = {
  severity: "info",
};

export default AlertInfo;
