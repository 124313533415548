import React from "react";
import AppButton from "./AppButton";

const SupportButton = () => {
  return (
    <AppButton
      label="Contact support"
      look="green"
      url={`mailto:support@globhe.com`}
      externalLink
      targetNewWidow
    />
  );
};

export default SupportButton;
