import { Box } from "@mui/material";
import * as React from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import AppButton from "../../components/AppButton";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../components/SupportButton";
import AlertInfo from "../../components/PageComponents/AlertInfo";
import { pageStyles, pageContent } from "../../styleConstants";

const button = {
  marginBottom: "30px",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <Box sx={pageContent}>
        <VerticalMenu page={3} />

        <Container maxWidth="md" sx={{ wordWrap: "break-word" }}>
          <PageTitle text="GLOBHE's order API" />
          <Paragraph
            text="This is the public order API of GLOBHE for the automated
            ordering high resolution Earth observation data that captured
            by drone. This API ordering service (Orders API) makes it easier to
            create pipelines to continuously ingest imagery for processing and
            analysis."
          />

          <ParagraphTitle text="Order and receive data automatically through your app" />
          <Paragraph
            text="With this API, you can order data directly, follow the order's
            status, update, and cancel the order. Once the data is ready, it can
            be received automatically through the delivery API."
          />
          <AlertInfo text="Please note that the ordering API currently supports only single-location orders." />
          <ParagraphTitle text="Get notified by webhooks" />
          <Paragraph
            text="Webhook functionality allows your backend services to be notified
            directly when your order is available."
          />
          <ParagraphTitle text="Get an API Key" />
          <Paragraph
            text="An API-KEY is needed in order to authenticate your requests. All
            registered clients receive an API key automatically after signing
            up. You can find it in your profile page. Sign up as a client to get
            your API key."
          />
          <AppButton
            look="black"
            label="Sign up"
            url="https://app.globhe.com/crowddroning/client/sign-up/"
            externalLink
            targetNewWidow
            addtionalStyle={button}
          />
          <ParagraphTitle text="Need help" />
          <Paragraph
            text="For questions and inquiries, you are more than welcome to contact
            GLOBHE's support."
          />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
