import theme from "./theme";

export const codeFont = {
  fontFamily: "'Inconsolata','Courier New',Courier,monospace",
  fontSize: ".875rem",
  color: "#f0506e",
  whiteSpace: "nowrap",
  padding: "2px 6px",
  background: theme.palette.black.paleSilver,
};

export const listItems = {
  paddingTop: "10px",
};

export const listStyle = {
  lineHeight: "25px",
  marginBottom: "50px",
};

export const pageStyles = {
  marginBottom: "100px",
};
export const pageContent = {
  display: "flex",
};
export const textHeader = {
  marginBottom: "20px",
};
export const body = {
  marginBottom: "40px",
};
